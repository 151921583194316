@import url('https://fonts.googleapis.com/css?family=Belleza|Federo|Open+Sans&display=swap');

body{
	background:#fff;
	color: #252525;
	margin:0;
	padding:0;
	min-height: 100%;
	font-size: 12px;
	font-family: "Open Sans",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}
html{
    min-height: 100%;
    overflow-x: hidden; 
    overflow-y: auto;
    overflow: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
button,
svg {
  display: inline-block;
  vertical-align: middle;
}
a{
	text-decoration: none;
}
h1,h2,h3,h4,h5,h6,p{
	font-size: 12px;
	font-weight: normal;
	margin: 0;
	padding: 0;
	color: #cecece;
}
ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}
::placeholder{
	color: #cecece;
	opacity: 1;
	font-size: 12px;
	font-family: "Open Sans",sans-serif;
}
:-ms-input-placeholder{
	color: #cecece;
	font-size: 12px;
	font-family: "Open Sans",sans-serif;
}
::-ms-input-placeholder{
	color: #cecece;
	font-size: 12px;
	font-family: "Open Sans",sans-serif;
}
.App {
  text-align: center;
}
.container{
    margin-top: 95px;
	background-color: #fff;
}

/* HEADER MOBILE */

.header_mobile {
    background-color: #050505;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
    width: 100%;
    z-index: 1000;
}
.header_mobile_top{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_mobile_bottom{
	background-color: #252525;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	-webkit-box-shadow: 0px 2px 5px 0px rgba(5, 5, 5, 0.4);
    -moz-box-shadow: 0px 2px 5px 0px rgba(5, 5, 5, 0.4);
    box-shadow: 0px 2px 5px 0px rgba(5, 5, 5, 0.4);
}
#menuButton{
	height: 30px;
	width: 30px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.fa-bars{
	font-size: 20px;
	color: #cecece;
}
#closeButton{
	height: 30px;
	width: 30px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #1d1d1d;
}
.fa-search{
	font-size: 20px;
}
.green{
	font-size: 20px;
	color: #00d8a8;
}
.fa-times{
	font-size: 20px;
	color: #cecece;
}
.logo a{
	display: flex;
}
.logo_img{
	height: 40px;
}
.search_btn{
	height: 30px;
	width: 30px;
	padding: 10px;
	display: flex;
    justify-content: center;
	align-items: center;
	z-index: 200;
}
.play{
	height: 20px;
	padding: 10px;
}
.logo_circle{
	height: 30px;
}
.header_title{
	font-size: 12px;
	color: #cecece;
	text-transform: capitalize;
}
.sort_btn{
	width: 40px;
	height: 30px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #353535;
}
.fa-sliders-h{
	font-size: 20px;
    color: #cecece;
}
.search_wrap{
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 100;
}
#search{
	display: flex;
}
.search_input{
	flex: 1;
	padding: 10px;
	border: none;
	outline: none;
	background-color: #2f2f2f;
	color: #cecece;
	font-size: 12px;
	font-family: "Open Sans",sans-serif;
}
.search_input:focus{
    background-color: #444;
}
#search_button{
	height: 40px;
	width: 50px;
	background-color: #1b1b1b;
    border: none;
    outline: none;
}
#search_suggestions{
	display: none;
	background-color: #fff;
	text-align: left;
	box-shadow: 0px 3px 5px 0px rgba(37, 37, 37, 0.4);
	z-index: 999999;
}
#search_suggestions li{
	height: 40px;
}
#search_suggestions li a{
	padding: 10px;
	color: #050505;
	font-size: 12px;
	font-family: "Open Sans",sans-serif;
	display: block;
}

/* MENU */

.menu_wrapper{
	display: flex;
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
}
#menu{
	background-color: #050505;
	width: 250px;
	text-align: left;
	height: 100%;
}
.menu{
	height: 100%;
    display: flex;
	flex-direction: column;
	background-color: #1d1d1d;
	overflow-y: scroll;
}
.menu_links{
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: #252525;
}
.menu_links a{
	padding: 10px;
	color: #cecece;
	background-color: #252525;
    display: flex;
    align-items: center;
}
.menu_footer{
	padding: 10px;
	background-color: #1d1d1d;
}
.menu_footer p{
	font-size: 10px;
}
.copyright{
	color: #00d8a8;
}
.overlay{
	flex: 1;
	background-color: rgba(5, 5, 5, 0.85);
}
.menu_title{
	padding: 10px;
	color: #cecece;
	background-color: #252525;
	display: flex;
	align-items: center;
}
.fa-coins{
	font-size: 12px;
    margin-left: 3px;
}
.fa-circle{
	font-size: 8px;
	margin: 0 4px;
	color: #00d8a8;
}
.menu_id{
	padding: 10px;
	display: flex;
	align-items: center;
}
.profile_id{
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
}
.fa-user{
    color: #cecece;
	font-size: 20px;
}
.menu_id span{
	color: #cecece;
	font-size: 14px;
	height: 20px;
}
.tokens{
    color: #fff;
    display: flex;
    align-items: center;
	justify-content: center;
	font-size: 14px;
	border-radius: 2px;
	background: #00d8a8;
	background: linear-gradient(0deg, #009070 0%, #00d8a8 100%);
	width: 90px;
    height: 30px;
}
.id{
	font-size: 14px;
    color: #cecece;
	margin-left: 10px;
}
.balance{
	color: #00d8a8;
	margin-top: 5px;
}
.active{
	background-color: #2d2d2d !important;
    color: #fff !important;
    border-left: 3px solid #00d8a8;
}
.menu_links svg{
	padding-left: 5px;
	font-size: 10px;
}
.space_fixer{
	width: 50px;
	height: 50px;
}


/* MODELS */

.users{
	width:calc(100% - 5px);
	min-height: 100vh;
}
.users:after{
	clear:both;
	content:" ";
	display:block;
}
.users>div{
	width:calc(50% - 5px);
	margin:5px 0 0 5px;
	float:left;
	position:relative;
}
.users>div>div{
	width:100%;
	padding-bottom:75%;
}
.users>div>div>div{
	position:relative;
}
.users>div img{
	width:100%;
	display:block;
	background-color:#fff;
}
.users>div a{
	top:0;
	left:0;
	right:0;
}
.users>div .username,.users>div a{
	text-decoration:none;
	position:absolute;
	bottom:0;
}
.users>div .username{
	color:#fff;
    padding: 2px 5px;
	font-size:10px;
	width: calc(100% - 10px);
    background: #050505;
    background: linear-gradient(to right, rgba(5, 5, 5, 0.8) 0%, rgba(5, 5, 5, 0.3) 65%, transparent 100%);
	overflow: hidden;
	text-align: left;
}

/* FILTERS */

.filters_container{
	position: absolute;
    top: 0;
	bottom: 0;
	width: 100%;
	background-color: #252525;
	height: 40px;
	overflow-x: hidden;
	overflow-y: hidden;
}
.filters{
    white-space: nowrap;
    width: 100%;
    overflow-x: scroll;
    padding: 0 5px;
    box-sizing: border-box;
}
.filter{
	font-size: 12px;
    color: #cecece;
    background-color: #1b1b1b;
    border: 1px solid rgba(0, 216, 168, 0.2);
    outline: none;
    height: 30px;
    padding: 0 10px;
    margin: 5px;
    border-radius: 5px;
}
.filter:hover{
	color: #00d8a8;
}
.fa-tag{
	font-size: 8px;
	color: #cecece;
}

/* FOOTER */

.footer{
	background-color: #252525;
	margin-top: 5px;
}
.footer_content{
	padding: 20px 10px;
	text-align: justify;
	color: #cecece;
}
.footer_content h1{
	font-size: 16px;
	font-family: 'Belleza', sans-serif;
    color: #00d8a8;
}
.footer_content p{
	padding-bottom: 10px;
}
.footer_content ul{
	margin: 20px 0;
}
.footer_content ul li{
	color: #cecece;
	padding: 5px;
}
.footer_content ul li:before{
	content: '✓';
	margin-right: 5px;
    color: #00d8a8;
}
.footer_sec{
	background-color: #050505;
	color: rgba(0, 216, 168, 0.9);
	padding: 10px;
    font-size: 10px;
}

/* CONTACT */

.contact{
	text-align: left;
	padding: 10px;
}
.contact h1{
	padding: 10px;
	color: #252525;
	font-size: 16px;
    font-weight: bold;
}
.contact p{
	padding: 10px;
	color: #252525;
}

/* 404 */

.not_found h1{
	text-align: left;
	padding: 20px;
	color: #252525;
	font-size: 16px;
	font-weight: bold;
}

/* CHATROOM */

.for_chatroom{
	margin-top: 90px;
}
iframe{
	border: none;
	padding: 0;
	margin: 0;
	background-color: #1b1b1b;
}
.chatroom{
	background-color: #1b1b1b;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    font-size: 14px;
	height: 75vw;
	color: #fff;
}
.chatroom-contentwrap{
    padding: calc(75vw - 90px) 0 10px 0;
}
.chatroom-bar{
    display: flex;
	padding: 5px;
	background-color: #323232;
}
.chatroom-bar h1{
	font-size: 14px;
	font-weight: normal;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	padding: 4px;
}
.chatroom-bar-model{
    flex: 1;
    background-color: #444;
	border: 1px solid #2d2d2d;
	overflow: hidden;
	border-radius: 2px;
}
.chatroom-bar-btns{
	display: flex;
}
.chatroom-bar-btn{
	margin-left: 2px;
	padding: 5px 12px;
    background: #444;
	color: #00d8a8;
	font-size: 12px;
	display: flex;
	align-items: center;
	border: 1px solid #2d2d2d;
	border-radius: 2px;
}
.credits{
    background: #00d8a8;
    background: linear-gradient(0deg, #009070 0%, #00d8a8 100%);
	color: #fff;
	border: 1px solid #2d2d2d;
}
.chatbox{
    position: fixed;
    bottom: 0;
    width: calc(100% - 10px);
    display: flex;
    padding: 5px;
	background-color: #1b1b1b;
	opacity: 0.95;
}
.chatbox input{
	width: 100%;
	padding: 10px;
	border: none;
	outline-color: rgba(0, 216, 168, 0.2);
	font-family: "Open Sans",sans-serif;
	background: none;
	color: #cecece;
	background-color: #252525;
}
#send-input::placeholder{
	color:#cecece;
}
#send-input:focus::placeholder{
	color: transparent;
}
.chatbox .send{
    padding: 10px 20px;
    background-color: #333;
    color: #00d8a8;
	border: none;
	display: flex;
	align-items: center;
}
.chatroom-info{
	padding: 10px;
	font-size: 12px;
	background-color: #252525;
}
.chatroom-info div{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #1f1f1f;
}
.chatroom-info span{
	padding-right: 10px;
	color: #00d8a8;
	font-size: 11px;
}
.chatroom-info p{
	margin: 0;
	text-transform: capitalize;
	text-align: justify;
	font-size: 11px;
}
.chatroom-contentwrap h4{
	padding: 10px;
	padding-top: 15px;
	color: #050505;
}
.fa-camera{
	color: #cecece;
}
.chatroom-profile-picture{
	width: 150px;
}
.highlight{
	color: #00d8a8;
}

/* DESKTOP HEADER */

.for_desktop{
	margin-top: 0;
}
.header_desktop{
	background-color: #050505;
}
.part_top{
	padding: 5px;
	display: flex;
    justify-content: space-between;
}
.part_logo{
	display: flex;
	align-items: center;
	color: #cecece;
}
.part_logo a{
	display: flex;
}
.part_menu ul{
	display: flex;
}
.part_menu ul li a{
    padding: 17px 12px;
    display: flex;
    align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #cecece;
	font-family: 'Belleza', sans-serif;
	transition: .1s ease;
}
.part_menu ul li a:hover{
	color: #00d8a8;
}
.active_link{
	color: #00d8a8 !important;
}
.models_online{
	margin-left: 5px;
	color: #cecece;
	font-family: 'Federo', sans-serif;
    display: flex;
    align-items: baseline;
}
.models_online span{
	color: #00d8a8;
	font-size: 14px;
	margin-right: 3px;
}
.part_bottom{
	background-color: #252525;
	display: flex;
}
.part_bottom_left{
	display: flex;
}
.part_bottom_left h1{
    font-family: 'Belleza', sans-serif;
    color: #00d8a8;
    align-self: center;
    height: 44px;
    display: flex;
    align-items: center;
	padding: 0 10px;
	background-color: #353535;
	text-shadow: 1px 1px 2px rgba(5, 5, 5, 0.25);
}
.networks{
	display: flex;
    align-items: center;
	padding: 0 5px;
	background-color: #2d2d2d;
}
.networks a{
    color: #cecece;
	background-color: #1d1d1d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    transition: .1s ease;
    text-transform: uppercase;
    padding: 5px 7px;
	margin: 0 5px;
	border: 1px solid rgba(0, 216, 168, 0.1);
}
.networks a:hover{
	border: 1px solid rgba(0, 216, 168, 0.2);
	color: #dadada;
}
.part_bottom_right{
	flex: 1;
}
.part_mixed{
	background-color: #585858;
	padding: 5px 10px;
	display: flex;
    align-items: center;
}
.part_title{
	flex: 1;
	text-align: left;
	overflow: hidden;
}
.part_title h1{
    font-size: 14px;
    display: flex;
    align-items: center;
    font-family: 'Belleza', sans-serif;
    text-transform: uppercase;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(5, 5, 5, 0.25);
}
.filter_tag{
	font-size: 10px;
    padding: 5px 7px;
    text-transform: uppercase;
    border: none;
    outline: none;
    cursor: pointer;
	margin: 5px 2px;
	background-color: #353535;
	color: #cecece;
	border: 1px solid #2d2d2d;
	transition: .1s ease;
	border-radius: 3px;
}
.filter_tag:hover{
	color: #00d8a8;
}



/* media queries */

@media only screen and (min-width: 568px) {
	.users>div {
		width: calc(25% - 5px);
	}
	.chatroom-bar-btn{
		padding: 5px 20px;
	}
}

@media only screen and (min-width: 768px) {
	.users>div {
		width: calc(20% - 5px);
	}
	#menu{
		width: 300px;
	}
	.menu_id{
		padding: 15px;
	}
	.menu_title{
		padding: 15px;
		font-size: 14px;
	}
	.menu_links a{
		padding: 15px;
		font-size: 14px;
	}
	.fa-user{
		font-size: 22px;
	}
	.id{
		font-size: 16px;
		text-transform: uppercase;
	}
	.balance{
		font-size: 14px;
	}
	.tokens{
		width: 110px;
		height: 35px;
		font-size: 16px;
	}
	.menu_footer p {
		font-size: 12px;
		text-align: center;
	}
	.chatroom-bar-btn {
		padding: 7px 25px;
	}
	.credits{
		padding: 7px 30px !important;
	}
}

/* Desktop */

@media only screen and (min-width: 769px) {
	.container{
		margin-top: 0;
	}
	.logo_img {
		height: 50px;
	}
	.search_wrap {
		position: relative;
	}
	#search{
		margin: 5px 10px;
	}
	#search_button{
		height: 34px;
		cursor: pointer;
		transition: .1s ease;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #353535;
	}
	#search_button:hover{
		background-color: #3a3a3a;
	}
	.search_input{
		background-color: #1d1d1d;
		padding: 7.5px;
		border: 1px solid #353535;
		border-right: none;
		color: #969696;
	}
	.search_input:focus{
		background-color: #191919;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border: 1px solid rgba(0, 216, 168, 0.15);
		border-right: none;
	}
	.fa-search {
		font-size: 15px;
	}
	::placeholder{
		color: #969696;
	}
	:-ms-input-placeholder{
		color: #969696;
	}
	::-ms-input-placeholder{
		color: #969696;
	}
	.fa-tag {
		color: #00d8a8;
	}
	.footer_content {
		padding: 20px;
	}
	.chatroom-contentwrap {
		padding: calc(75vw - 147px) 0 10px 0;
	}
	.chatbox{
		display: none;
	}
	.chatroom-bar-btn {
		padding: 7px 30px;
		transition: .1s ease;
	}
	.chatroom-bar-btn:hover{
		background-color: #4c4c4c;
	}
	.chatroom-bar h1{
		font-size: 15px;
	}
	.fa-circle {
		font-size: 9px;
		margin: 8px 4px;
	}
	.chatroom-info span{
		font-size: 13px;
	}
	.chatroom-info p {
		font-size: 13px;
	}
	.users>div .username{
		font-size: 11px;
	}
	.credits{
		padding: 7px 100px !important;
		transition: .1s ease !important;
	}
	.credits:hover{
		background: #009070;
		background: linear-gradient(0deg, #009070 0%, #009070 100%);
	}
	.chatroom-contentwrap h4 {
		font-size: 14px;
	}
	#search_suggestions{
		position: absolute;
		top: 39px;
		left: 10px;
		background-color: #1d1d1d;
	}
	#search_suggestions li{
		height: 34px;
	}
	#search_suggestions li:hover{
		background-color: #212121;
	}
	#search_suggestions li a{
		color: #969696;
		padding: 8.5px;
	}
	#search_suggestions li a:hover{
		color: #00d8a8;
	}
}

@media only screen and (min-width: 1024px) {
	.users>div {
		width: calc(16.6666666667% - 5px);
	}
	.part_menu ul li a{
		padding: 17px;
	}
	.part_bottom_right {
		flex: unset
	}
	.part_bottom{
		justify-content: space-between;
	}
	.search_input {
		width: 250px;
	}
	.chatroom{
		position: relative;
		margin:auto;
	}
	.chatroom-contentwrap{
		padding:0;
	}
}

@media only screen and (min-width: 1280px) {
	.users>div {
		width: calc(14.2857142857% - 5px);
	}
	.part_menu ul li a {
		padding: 17px 20px;
	}
	.networks a{
		padding: 5px 12px;
	}
}

@media only screen and (min-width: 1520px) {
	.users>div {
		width: calc(12.5% - 5px);
	}
}

@media only screen and (min-width: 1760px) {
	.users>div {
		width: calc(11.1111111111% - 5px);
	}
}

@media only screen and (min-width: 1920px) {
	.users>div {
		width: calc(10% - 5px);
	}
}

@media only screen and (min-width: 2200px) {
	.users>div {
		width: calc(9.09090909091% - 5px);
	}
}

@media only screen and (min-width: 2560px) {
	.users>div {
		width: calc(8.33333333333% - 5px);
	}
}